#editor {
  height: calc(100% - 70px);
  overflow: hidden;
}

/* Let's highlight canvas boundaries */
#cc {
  background: solid;
  background-color: #738dc9;
  z-index: 1000;
}

#pageMenu {
  height: auto;
  max-height: auto;
  width: 100%;
}

#dropdownMenuButton1 {
  background-color: #f8f9fa;
  color:  #000000;
  border-color:  #cccccc;
  border-width: 2px;
}

#dropdownMenuButton1:hover {
  background-color: #cccccc;
  color:  #000000;
  border-color:   #738dc9;
}

#dropdownMenuButton1:active {
  background-color: #333333;
  color:  #ffffff;
  border-color:  #fff;
  border-width: 2px;
}

body {
  background: solid;
  background-color: #f8f9fa;
}

.cc-block:hover {
  background-color: #a6d7ff;
}

#cc-block-svg  {
  width: 40px;
}
.cc-block__media > svg {
  width: 50px;
}
.cc-block-label {
  font-size: 15px;
  font-weight: lighter;
  font-family: Avenir Next LT Pro, sans-serif;
  margin-top: 15px;
}
.cc-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: Avenir Next LT Pro, sans-serif;
}
.cc-block-label > svg {
  width: 40px;
  margin-bottom: 10px;
}


/* Tail WInd */

body, html {
  margin: 0;
  height: 100%;

}


/* Fit icons properly */
.cc-block {
  padding-top: 50px !important;
 padding-bottom: 50px !important;
  width: 100% !important;
  min-height: auto !important;
}
.cc-block-svg {
  margin-bottom: 10px;
}

.cc-block > svg {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1250px) {
  /* Make blocks full width */
.cc-block {
  padding-top: 50px !important;
 padding-bottom: 50px !important;
 padding-right: 25px !important;
 padding-left: 25px !important;
  width: 100% !important;
  min-height: auto !important;
}

/* Fit icons properly */
.cc-block svg {
    width: 50px;
}
}

body, html {
  margin: 0;
  height: 100%;
}

#newcol {
}

#indexTop {
  background: linear-gradient(255deg,#cfe5ff,#edf6ff,#aeffc5,#ffafee,#e0adff,#caebff);
  background-size: 720% 720%;
  animation: gradient-animation 84s ease infinite;
}
#auth-signup{
  background: linear-gradient(255deg,#cfe5ff,#edf6ff,#aeffc5,#ffafee,#e0adff,#caebff);
  background-size: 720% 720%;
  animation: gradient-animation 84s ease infinite;
}
#auth-login{
  background: linear-gradient(255deg,#cfe5ff,#edf6ff,#aeffc5,#ffafee,#e0adff,#caebff);
  background-size: 720% 720%;
  animation: gradient-animation 84s ease infinite;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.add-page {
    background: #f8f9fa;
    color: black;
    font-size: 12px;
    padding: 8px 5px;
    border-radius: 2px;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 10px;
}




/*Loading Popup CSS*/

#loading-popup {
  position:absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vw;
  display: none;
  justify-content: center;
  z-index: 10001;
}

#spinner {
  margin-top: calc(300px + 2vw);
}

#container {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
}


.cp-btn-container .cc-btn-prim {
  margin: 0px;
  margin-right: 10px;
}

#sideList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}
#sideMenuOption {
  width: 150px;
  height:150px;
  margin: 10px;
}
.page {
  color: black;
}
.page.selected {
  color: black;
  border: 1px solid #f8f9fa;
  background-color: rgba(0, 0, 0, 0.1);
}
.cc-pn-buttons {
  flex-wrap: wrap;
}

.cc-pn-buttons {
  background: solid;
  background-color: white;
}

.cc-pn-btn:hover {
  border: solid;
  border-color: #333333;
  border-width: 1px;
  border-radius: 5px;
}

#thumbnailUploadButton{
  font-size: 15px;
}

#thumbnailInput{
  font-size: 15px;
  width: 355px;
}

#list-group-item {
  width: 355px;
}

#cc-sm-images {
  background-color: #0d6efd;
  border: solid;
  border-width: 1px;
  border-color: white;
}
#cc-sm-images:hover {
  background-color: #257bfa;
}
