@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-ExtraBold.woff2') format('woff2'),
        url('Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-It.woff2') format('woff2'),
        url('AvenirNextLTPro-It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('AvenirNextLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1 {
      font-family: 'Gilroy';
}

p {
  font-family: 'Avenir Next LT Pro';
}

.nav-link {
  font-family: 'Avenir Next LT Pro';
  font-weight: normal;
}
