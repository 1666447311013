#template1 {
  opacity: 85%;
  min-width: 350px;
  width: 600px;
  border-radius: 25px;
  background: rgba( 248, 249, 250, 0.65 );
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 0px );
  border-radius: 10px;
  border: 2px solid rgba( 255, 255, 255, 0.18 );
}

#template2 {
  min-width: 350px;
  width: 600px;
  border-radius: 25px;
  background: rgba( 248, 249, 250, 0.65 );
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 0px );
  border-radius: 10px;
  border: 2px solid rgba( 255, 255, 255, 0.18 );
}


#templateInner {
  max-width: 1500px;
}


#gradientBackground {
  background: linear-gradient(255deg,#cfe5ff,#edf6ff,#aeffc5,#ffafee,#e0adff,#caebff);
  background-size: 720% 720%;
  animation: gradient-animation 84s ease infinite;
}


#dropDownContainer {
  min-height: 500px;
  width: auto;

}

#accordionExample {
  width: 80%;
}

#introframe {
  border-radius: 5px;
  background: #212529;
  box-shadow:  19px 19px 38px #0d0f10,
               -19px -19px 38px #353b42;
}

@media only screen and (max-width: 600px) {
  #dropDownContainer {
    min-height: 250px;

  }
}












/* Start of the video card css*/
#stepcard{
  min-width: 250px;
  max-width: 650px;
  height: auto;
  min-height: 250px;
  background: none;
}

#innercard{
  margin-top: 10px;
  min-width: 150px;
  height: auto;
  min-height: 250px;
  background: solid;
  background-color: yellow;
  border-radius: 20px;
background: #f0f0f0;
box-shadow:  23px 23px 45px #cccccc,
             -23px -23px 45px #ffffff;
}

#cardCounter{
  border: solid;
  border-width: 5px;
  border-color: #f0f0f0;
  background: #212529;
  box-shadow:  23px 23px 45px #cccccc,
             -23px -23px 45px #ffffff;
  width: 75px;
  min-width: 75px;
  height: 75px;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  border-radius: 90px;
}
#number{
  font-size: 30px;
  font-weight: bold;
  color: white;
  margin: auto;
}

#card-description {
}
#h2card {
  line-height: 30px;
  font-size: 25px;
  width: 100%;
}

video {
    border-radius: 10px;
}
/* End of the video card css*/
