.context-menu {
     position: absolute;
     text-align: center;
     background: lightgray;
     border: 1px solid black;
 }
 .context-menu ul {
     padding: 0px;
     margin: 0px;
     min-width: 150px;
     list-style: none;
 }
 .context-menu ul li {
     padding-bottom: 7px;
     padding-top: 7px;
     border: 1px solid black;
 }
 .context-menu ul li a {
     text-decoration: none;
     color: black;
 }
 .context-menu ul li:hover {
     background: darkgray;
 }
