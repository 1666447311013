html, body {
  font-size: 18px;
  background-color: #fff;
  color: #000;
  letter-spacing: 1px;
  font-family: 'Avenir Next LT Pro', sans-serif;
}

#sidebar {

background: solid;
min-height: 100vw;
heigh: 100%;
}
/*------------NAV BAR----------------*/

#navbar {
  height: 100%;
  position: fixed;
}

.nav header {
}

.nav-link:link {
  color: #fff;
}
.nav-link:hover,
.nav-link:active {
  color: #000000;
  background-color: #efefef;
  font-wight: bolder;
  transition: 0.3s;
  text-decoration: underline;
}

.nav-link:visited {
  color: #dfdfdf;
}

.head-box {
  background-color: #212529;
  color: #fff;
  padding: 20px;
  text-align: center;
}


/*-------------BODY-----------*/

header {
  margin-top: 20px;
}

h2 {
}

h3 {
}

p {

}

section article {
  padding-right: 20px;
}

article li {
  font-size: 90%;

}

/*---CODE SECTION---*/

pre {
  display: inline-block;
  background-color: #212529;
  color: #fff;
  padding: 0 0 20px 30px;
  font-size: 80%;
  width: 90%;
  white-space: pre-line;

}

/*-------FOOTER------*/

hr {
  border: 0.5px solid;
  color: #212529;
}

a {
  text-decoration: none;
  font-family: 'Avenir Next LT Pro', sans-serif;
  font-color: blue;
}

a:hover {
  text-decoration: none;
  color: green;
}



footer {
  text-align: center;
}

footer p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 90%;
}

/*---------RESPONSIVE--------*/

@media only screen and (max-width: 768px) {
  #navbar {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  pre {
    font-size: 60%;
    max-width: 80%;
    padding: 0 10px 10px 20px;
  }
}


#top1 {

}

#form-auth-signup {
border-radius: 25px;
background: rgba( 248, 249, 250, 0.65 );
backdrop-filter: blur( 0px );
-webkit-backdrop-filter: blur( 0px );
border-radius: 10px;
border: 2px solid rgba( 255, 255, 255, 0.18 );

}
